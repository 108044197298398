import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment/moment';import swal from 'sweetalert';

@Injectable()
export class ErrorsService {

  constructor(
    public router: Router,
    public translateService: TranslateService,
  ) {}

  public logHttpError(route: string, error: Error): any {
    this.logError(route, error);
    if (error['status']==498) {
      console.log('Status is 498');
      location.reload();
    } else if (error['status']==310) {
      console.log('Status is 310');
      window.location.href = window.location.href;
    }
  }

  public handleErrorMessage(error?: Error) {
    if (error && (error['status']==498  || error['status']==310)) return;
    if (error && error['status']==401) {
      this.router.navigate(['/auth/login']);
      swal(this.translateService.instant('errors.handle_error_message_401_swal.title'), this.translateService.instant('errors.handle_error_message_401_swal.text1')+'\n\r\n\r'+this.translateService.instant('errors.handle_error_message_401_swal.text2'), 'warning');
    } else if (error && error['status']==403) {
        this.router.navigate(['/auth/login']);
        swal(this.translateService.instant('errors.handle_error_message_403_swal.title'), this.translateService.instant('errors.handle_error_message_403_swal.text1')+'\n\r\n\r'+this.translateService.instant('errors.handle_error_message_403_swal.text2'), 'warning');
    } else if (error && error['status']==404) {
      location.href=('https://www.esculon.eu');
    } 
    else if (error && error['status']==460) {
      this.router.navigate(['/intakes/unauthenticated']);
    } else {
      if (error['status']==0) swal(this.translateService.instant('errors.handle_error_message_connection_swal.title'), this.translateService.instant('errors.handle_error_message_connection_swal.text'), 'error');
      else {
        this.logError('', error);
        swal(this.translateService.instant('errors.handle_error_message_swal.title'), this.translateService.instant('errors.handle_error_message_swal.text'), 'error');
      }
    }
  }

  public handleOverlapError(type: string) {
    if (type=='treatment') swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.handle_overlap_error.treatment'), 'warning');
    if (type=='availability') swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.handle_overlap_error.availability'), 'warning');
    if (type=='activity') swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.handle_overlap_error.activity'), 'warning');
  }

  public handleDuplicationOverlaps(dates: string[]) {
    let duplicateString = '';
    dates.forEach((date, i) => {
      if (i>0) duplicateString += ', ';
      duplicateString += moment(date).format('DD-MM-YYYY HH:mm');
    });
    swal(this.translateService.instant('errors.handle_duplication_overlaps.title'), this.translateService.instant('errors.handle_duplication_overlaps.text')+' '+duplicateString, 'warning');
  }

  public handleRecordDoesNotExistError() {
    swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.record_does_not_exist'), 'warning');
  }

  public handleEmailDuplicateError() {
    swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.email_duplicate'), 'warning');
  }

  public handleDeleteAuthenticatedAccountError() {
    swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.delete_authenticated_account'), 'warning');
  }

  public handleInvalidDateError() {
    swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.invalid_date'), 'warning');
  }

  public handleInvalidEmailError() {
    swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.invalid_email'), 'warning');
  }

  public handleSendIntakeError(type: string) {
    if (type=='anamnese_in_past') swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.handle_sent_intake.anamnese_in_past'), 'warning');
    if (type=='no_email_address') swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.handle_sent_intake.no_email_address'), 'warning');
    if (type=='invalid_email_address') swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.handle_sent_intake.invalid_email_address'), 'warning');
  }

  public handleSendCommunicationError(type: string) {
    swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_communication'), 'warning');
  }

  public handleUploadAttachmentError() {
    swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.upload_attachment'), 'warning');
  }

  public handleSendInvoiceError(type: string) {
    if (type=='no_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_invoice.no_email_address'), 'warning');
    }
    if (type=='invalid_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_invoice.invalid_email_address'), 'warning');
    }
  }

  public handleSendPaymentReminderError(type: string) {
    if (type=='no_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_payment_reminder.no_email_address'), 'warning');
    }
    if (type=='invalid_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_payment_reminder.invalid_email_address'), 'warning');
    }
  }

  public handleSendAppointmentReminderError(type: string) {
    if (type=='treatment_in_past') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_appointment_reminder.treatment_in_past'), 'warning');
    }
    if (type=='no_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_appointment_reminder.no_email_address'), 'warning');
    }
    if (type=='invalid_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_appointment_reminder.invalid_email_address'), 'warning');
    }
    if (type=='no_phone') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_appointment_reminder.no_phone'), 'warning');
    }
    if (type=='invalid_phone') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_appointment_reminder.invalid_phone'), 'warning');
    }
  }

  public handleSendAppointmentConfirmationError(type: string) {
    if (type=='treatment_in_past') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_appointment_confirmation.treatment_in_past'), 'warning');
    }
    if (type=='no_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_appointment_confirmation.no_email_address'), 'warning');
    }
    if (type=='invalid_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.send_appointment_confirmation.invalid_email_address'), 'warning');
    }
  }

  public handleAcceptRequestError(type: string) {
    if (type=='no_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.accept_request'), 'warning');
    }
  }

  public handleDeclineRequestError(type: string) {
    if (type=='no_email_address') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.decline_request'), 'warning');
    }
  }

  public handleAddWaitinglistitemError(type: string) {
    if (type=='waitinglistitem_exists') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.add_waitinglistitem'), 'warning');
    }
  }

  public handleEditInvoiceError(type: string) {
    if (type=='duplicate_reference') {
      swal(this.translateService.instant('errors.cancelled'), this.translateService.instant('errors.edit_invoice'), 'warning');
    }
  }


  public logError(scope: string, error: Error) {
    console.error('=== Start error: ', scope, ' ===');
    console.error(error);
    console.error('=== End error: ', scope, ' ===');
  }

}
